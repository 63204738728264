@media print {
    .container {
        margin: 0 !important;
    }
    .arrowButton {
        visibility: hidden;
    }
    .tableHeader {
        display: block;
    }
    .summary {
        min-height: auto !important;
        >div {
            margin: 0 !important;
        }
    }
}