@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

//CSS VARIABLES
:root {
    --baseFont: 'Roboto', sans-serif;
    --colorBlueBackground: #253979;
    --colorGreyBackground: #eeeeee;
}

//helpful to have but would clash with the apps existing styling
// * {
//     box-sizing: border-box;
// }

// body {
//     margin: 0;
//     font-family: var(--baseFont);
//     -webkit-font-smoothing: antialiased;
//     -moz-osx-font-smoothing: grayscale;
// }